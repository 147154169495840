<template>
    <el-container>
        <el-header>
            <HeadBar></HeadBar>
        </el-header>
        <el-main>
            <AppMain></AppMain>
        </el-main>
    </el-container>
</template>

<script>
import HeadBar from "@/layout/componentss/header.vue";
// import SlideBar from "@/layout/componentss/sliderBar.vue";
import AppMain from "@/layout/componentss/main.vue";
export default {
    name: "myLayout",
    components: {
        HeadBar,
        // SlideBar,
        AppMain
    }
}
</script>

<style scoped>
.el-container{
    width: 100%;
    height: 100%;
}
.el-header {
    padding: 0 !important;
}

.el-main {
    padding: 0 !important;
}
</style>