//该文件专门用于创建整个路由器
import vueRouter from "vue-router"
import layout from "@/layout/index.vue"
import moveViewsIndex from "@/layout/moveViewsIndex.vue"

/**
 * 待定  jurisdiction 为控制权限,PC为PC端,move:为移动端 
 * 
 */
//为啥加下面这段代码 自己看
//https://wenku.baidu.com/view/16f9453368d97f192279168884868762cbaebb48.html
const originalPush = vueRouter.prototype.push
// @ts-ignore
vueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    // @ts-ignore
    return originalPush.call(this, location).catch(err => err)
}

//创建并暴露一个路由器 
const router = new vueRouter({
    routes: [
        {
            path: '/',
            component: layout,
            children: [
                {
                    path: '/',
                    component: () => import('@/pcViews/homePage/HomePage.vue'),
                    meta: {
                        title: 'PC端首页',
                        jurisdiction: "PC"
                    },

                },
                {
                    path: '/download',
                    component: () => import('@/pcViews/download/index.vue'),
                    meta: {
                        title: '软件下载',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution',
                    component: () => import('@/pcViews/solution/index.vue'),
                    meta: {
                        title: '解决方案',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/realname',
                    component: () => import('@/pcViews/solution/realname/index.vue'),
                    meta: {
                        title: '建筑工人实名制管理系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/towercrane',
                    component: () => import('@/pcViews/solution/towercrane/index.vue'),
                    meta: {
                        title: '塔机安全监控管理系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/lifter',
                    component: () => import('@/pcViews/solution/lifter/index.vue'),
                    meta: {
                        title: '升降机安全监控管理系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/loadhook',
                    component: () => import('@/pcViews/solution/loadhook/index.vue'),
                    meta: {
                        title: '塔机吊钩导航系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/surroundings',
                    component: () => import('@/pcViews/solution/surroundings/index.vue'),
                    meta: {
                        title: '环境监测系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/video',
                    component: () => import('@/pcViews/solution/video/index.vue'),
                    meta: {
                        title: '视频监控管理系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/concrete',
                    component: () => import('@/pcViews/solution/concrete/index.vue'),
                    meta: {
                        title: '混凝土质量动态追踪及检测监管系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/solution/deeppit',
                    component: () => import('@/pcViews/solution/deeppit/index.vue'),
                    meta: {
                        title: '深基坑监测管理系统',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/aboutUs',
                    component: () => import('@/pcViews/aboutUs/aboutUs.vue'),
                    meta: {
                        title: '关于我们',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/propagandize',
                    component: () => import('@/pcViews/propagandize/index.vue'),
                    meta: {
                        title: '宣传视频',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/propagandize/details',
                    component: () => import('@/pcViews/propagandize/videoDetails/videoDetails.vue'),
                    meta: {
                        title: '视频详情',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/journalism',
                    component: () => import('@/pcViews/newsInformation/index.vue'),
                    meta: {
                        title: '新闻资讯',
                        jurisdiction: "PC"
                    },
                },
                {
                    path: '/journalism/allNewsParticulars',
                    component: () => import('@/pcViews/newsInformation/allNewsParticulars/allNewsParticulars.vue'),
                    meta: {
                        title: '全部新闻详情',
                        jurisdiction: "PC" 
                    },
                },
            ]
        },
        {
            path: '/min',
            component: moveViewsIndex,
            children: [
                {
                    path: '/min',
                    component: () => import('@/moveViews/homePage/homeIndex.vue'),
                    meta: {
                        title: '首页',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution',
                    component: () => import('@/moveViews/moveSolution/index.vue'),
                    meta: {
                        title: '解决方案',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/realname',
                    component: () => import('@/moveViews/moveSolution/realname/index.vue'),
                    meta: {
                        title: '建筑工人实名制管理系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/towercrane',
                    component: () => import('@/moveViews/moveSolution/towercrane/index.vue'),
                    meta: {
                        title: '塔机安全监控管理系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/lifter',
                    component: () => import('@/moveViews/moveSolution/lifter/index.vue'),
                    meta: {
                        title: '升降机安全监控管理系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/loadhook',
                    component: () => import('@/moveViews/moveSolution/loadhook/index.vue'),
                    meta: {
                        title: '塔机吊钩导航系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/surroundings',
                    component: () => import('@/moveViews/moveSolution/surroundings/index.vue'),
                    meta: {
                        title: '环境监测系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/video',
                    component: () => import('@/moveViews/moveSolution/video/index.vue'),
                    meta: {
                        title: '视频监控管理系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/deeppit',
                    component: () => import('@/moveViews/moveSolution/deeppit/index.vue'),
                    meta: {
                        title: '深基坑监测管理系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveSolution/concrete',
                    component: () => import('@/moveViews/moveSolution/concrete/index.vue'),
                    meta: {
                        title: '混凝土质量动态追踪及检测监管系统',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveJournalism',
                    component: () => import('@/moveViews/newsInformation/index.vue'),
                    meta: {
                        title: '新闻资讯',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveJournalism/allNewsParticulars',
                    component: () => import('@/moveViews/newsInformation/allNewsParticulars/allNewsParticulars.vue'),
                    meta: {
                        title: '全部新闻详情',
                        jurisdiction: "move" 
                    },
                },
                {
                    path: '/movePropagandize',
                    component: () => import('@/moveViews/movePropagandize/index.vue'),
                    meta: {
                        title: '宣传视频',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/movePropagandize/details',
                    component: () => import('@/moveViews/movePropagandize/videoDetails/videoDetails.vue'),
                    meta: {
                        title: '视频详情',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveDownload',
                    component: () => import('@/moveViews/moveDownload/index.vue'),
                    meta: {
                        title: '软件下载',
                        jurisdiction: "move"
                    },
                },
                {
                    path: '/moveAboutUs',
                    component: () => import('@/moveViews/moveAboutUs/index.vue'),
                    meta: {
                        title: '关于我们',
                        jurisdiction: "move"
                    },
                },
            ]
        },
    ]
})
export default router