<template>
  <div class="myBox">
      <div class="myBox-up">
        <el-row class="myBox-row">
          <el-col :span="9" class="myBox-col">
            <div class="myBox-one-name">友情链接</div>
            <div class="myBox-one-chunk">
              <div class="myBox-one-left">
                <el-link href="https://www.aliyun.com" :underline="false" target="_blank">阿里云</el-link>
                <el-link href="http://proudsmart.com" :underline="false" target="_blank">普奥云</el-link>
                <el-link href="https://www.tongfangpc.com" :underline="false" target="_blank">清华同方</el-link>
                <!-- <el-link href="#" :underline="false" target="_blank">国联达科技</el-link> -->
                <!-- <el-link href="http://www.hnscri.com" :underline="false" target="_blank">湖南新型智慧城市研究院</el-link> -->
                <el-link href="http://www.10010.com/net5/074" :underline="false" target="_blank">中国联通</el-link>
              </div>
              <div class="myBox-one-right">
                <el-link href="https://www.hncu.edu.cn" :underline="false" target="_blank">湖南城市学院</el-link>
                <el-link href="https://www.dahuatech.com" :underline="false" target="_blank">大华科技</el-link>
                <el-link href="http://www.zeno-tech.com" :underline="false" target="_blank">杭州智诺</el-link>
              </div>
            </div>
          </el-col>
          <el-col :span="9" class="myBox-col">
            <div class="myBox-one-name">联系方式</div>
            <div class="contact">
              <span>地址：</span>
              <span>湖南省益阳市赫山区海棠路</span>
            </div>
            <div class="contact">
              <span>电话：</span>
              <span>4006560008</span>
            </div>
            <div class="contact">
              <span>邮箱：</span>
              <span>contact@jcsmart.com.cn</span>
            </div>
          </el-col>
          <el-col :span="4" class="public">
            <div class="public-box">
              <div>公众号</div>
              <el-image class="public-pic" :src="require('@/assets/components/commonality/vcode.png')" />
              <div>微信扫一扫 关注我</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="myBox-reference">
        <el-link href="http://beian.miit.gov.cn" :underline="false" target="_blank">湘ICP备19012616号</el-link>
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1801227859973324800&language=CN&certType=8&url=www.jcsmart.com.cn" target="_blank" style="position: relative;display: inline-block;height: 38px;margin-left: 10px;"><div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1801227859973324800=www.jcsmart.com.cn.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
      </div>
  </div>
</template>

<script>
export default {
  name: "myFooter"
}
</script>

<style scoped>
.myBox {
  height: calc(100vw * 310 / 1920);
  color: #FFFFFF;
}


.myBox-up {
  height: 80%;
  background-color: #222429;
}

.myBox-row {
  margin: auto;
  width: calc(100vw * 1200 / 1920);;
  height: 100%;
  padding-top: calc(100vw * 12 / 1920);
}

.myBox-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.myBox-one-name {
  font-size: calc(100vw * 18 / 1920);
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: calc(100vw * 36 / 1920);
}

.myBox-one-chunk {
  flex: 1;
  display: flex;
}

.myBox-one-left,
.myBox-one-right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  line-height: calc(100vw * 40/ 1920);
}

.myBox-one-right {
  margin-left: calc(100vw * 100 / 1920);
}

.el-link {
  color: #FFFFFF !important;
  text-align: left;
  justify-content: start;
  font-size: calc(100vw * 14 / 1920);
}
.el-link:hover{
  color: #409EFF !important;
}
.contact {
  line-height: calc(100vw * 40/ 1920);
  font-size: calc(100vw * 14 / 1920);
}

.public {
  display: flex;
  align-items: center;
  height: 100%;
}

.public-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(100vw * 14 / 1920);
}

.public-pic {
  margin: calc(100vw * 16/ 1920) 0;
  width: calc(100vw * 100/ 1920);
  height: calc(100vw * 100/ 1920);
  font-size: 0;
}

.myBox-reference {
  height: 20%;
  background-color: #151922;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(100vw * 14 / 1920);
}
</style>