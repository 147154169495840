<template>

    <el-dialog title="" :visible.sync="dialogVisible" width="500px" @close="close">
        <div class="dialog-box">
            <el-image :src="require('@/assets/components/myDialog/face.png')" />
            <div class="dialog-text">
                <span>客服电话：4006560008</span>
                <el-image class="dialog-pic" :src="require('@/assets/components/myDialog/phone.png')" />
            </div>
        </div>
    </el-dialog>

</template>

<script>
export default {
    name: "myDialog",
    props: ["dialogSwitch"],
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        close() {
            this.$emit("close")
        }
    },
    watch: {
        dialogSwitch(value) {
            this.dialogVisible = value;
        }
    }
}
</script>

<style scoped>
.dialog-box {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.dialog-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.dialog-pic {
    font-size: 0;
    margin-left: 10px;
}

.el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

}

.el-dialog__wrapper>>>.el-dialog {
    border-radius: 10px;
}

.el-dialog__wrapper>>>.el-dialog__body {
    padding: 0 10px 20px;
}
</style>